<template>
  <div>
    <!-- Error Handler Alert -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body" v-for="(values , index) in errors_back" :key="index">
        <ul v-for="(value , valueIndex) in values" :key="valueIndex">
          <li v-for="(val , indexVal) in value" :key="indexVal">{{ val }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- validtion submit  -->
    <b-alert
      v-height-fade.appear
      fade
      :show="variantsCounter"
      @dismissed="variantsCounter = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">Please Add at least one variant</div>
    </b-alert>
    <!--Bulk Action Collapse Section -->
    <b-row>
      <b-col>
        <app-collapse accordion :type="collapseType" class="pb-1">
          <app-collapse-item
            v-if="authPermissions.includes('update-product-status')"
            title="Actions"
            class="rounded-lg"
          >
            <div class="custom-search">
              <b-row class="py-1">
                <b-col v-if="authPermissions.includes('restore')" md="12">
                  <!-- set status bulk -->
                  <b-button
                    variant="primary"
                    class="w-100"
                    v-b-modal.modal-restore
                    @click="deDuctCountOfVariant()"
                    >Restore Selected Variants</b-button
                  >
                  <b-modal
                    v-if="selectedRowsId.length > 0"
                    id="modal-restore"
                    cancel-variant="outline-secondary"
                    @ok="applyBulkStatusUpdate()"
                    ok-title="Ok"
                    cancel-title="Close"
                    centered
                    title="Update Product Status"
                    >"are you sure you want to Change status of
                    {{ selectedRowsId.length }} Variants?
                  </b-modal>
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <!-- Filter Collapse Section-->
    <b-row>
      <b-col>
        <app-collapse accordion :type="collapseType" class="pb-1">
          <app-collapse-item title="Filter" class="rounded-lg">
            <div class="custom-search">
              <b-row class="py-1">
                <b-col md="4">
                  <b-form-group label="Brands" label-for="Brands">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="brands"
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      :state="brands == null ? false : true"
                      v-model="brandModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Vendor" label-for="Vendor">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="vendors"
                      v-model="vendorModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Category" label-for="Category">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="categories"
                      v-model="categoriesModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Status" label-for="Status">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="statusData"
                      v-model="statusModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Item" label-for="Item">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="itemsBucket"
                      v-model="itemsModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Min Quantity" label-for="Min Quantity">
                    <b-form-input
                      :min="minQuan"
                      :max="maxQuan"
                      v-model="minQuan"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Max Quantity" label-for="Max Quantity">
                    <b-form-input
                      :min="minQuan"
                      :max="maxQuan"
                      v-model="maxQuan"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="Min Price" label-for="Max">
                    <b-form-input
                      :min="minPrice"
                      :max="maxPrice"
                      :value="minPrice"
                      v-model="minPrice"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="Max Price" label-for="Max">
                    <b-form-input
                      :min="minPrice"
                      :max="maxPrice"
                      :value="maxPrice"
                      v-model="maxPrice"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-row class="pt-1">
                    <b-col cols="4" class="pt-1">
                      <h4>Type :</h4>
                    </b-col>
                    <b-col cols="8" class="pt-1 d-flex">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                          class="d-flex"
                          id="checkbox-group-2"
                          button-variant="outline-primary"
                          :aria-describedby="ariaDescribedby"
                          name="flavour-2"
                        >
                          <b-form-radio
                            v-model="selected"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            value="new"
                            >New</b-form-radio
                          >
                          <b-form-radio
                            class="ml-1"
                            v-model="selected"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            value="used"
                            >Used</b-form-radio
                          >
                          <b-form-radio
                            class="ml-1"
                            v-model="selected"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            value="all"
                            >All</b-form-radio
                          >
                        </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="pt-1">
                    <b-col md="5" class="pt-1">
                      <h4>Sales Channel :</h4>
                    </b-col>
                    <b-col md="6" class="pt-1 d-flex">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                          class="d-flex"
                          id="checkbox-group-2"
                          button-variant="outline-primary"
                          :aria-describedby="ariaDescribedby"
                          name="flavour-2"
                        >
                          <b-form-radio
                            v-model="selectedClassification"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            value="all"
                            >All</b-form-radio
                          >
                          <b-form-radio
                            class="ml-1"
                            v-model="selectedClassification"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="1"
                            >Portal</b-form-radio
                          >
                          <b-form-radio
                            class="ml-1"
                            v-model="selectedClassification"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="0"
                            >In Store</b-form-radio
                          >
                        </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    class="px-3"
                    variant="outline-dark"
                    @click="resetFilter()"
                    >Reset</b-button
                  >
                  <b-button class="btn-color ml-3" @click="fetchData()">
                    Apply Filter
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row>
      <b-col cols="12">
        <b-card title="Archived Main Table" id="main-table">
          <!-- sorting action -->
          <b-row class="">
            <b-col cols="6">
              <b-form-group
                label="Sort By"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="d-flex align-items-center"
              >
                <b-input-group class="d-flex">
                  <b-form-select
                    id="sortBySelect"
                    @change="fetchData"
                    v-model="sortBy"
                    value="desc"
                    size="sm"
                    :options="sortOptionss"
                  >
                  </b-form-select>
                  <b-form-select
                    @change="fetchData"
                    v-model="orderBy"
                    value="deleted_at"
                    size="sm"
                    :options="orderOptions"
                  >
                  </b-form-select>
                </b-input-group> </b-form-group
            ></b-col>
            <!-- search input -->
            <b-col cols="3">
              <div class="ml-auto mr-2">
                <b-dropdown
                  style="height: 2.142rem; line-height: 0.5"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="RemoveExitem"
                  right
                  variant="primary"
                  size="sm"
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content
                    ><feather-icon
                      icon="PrinterIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <download-excel :data="exportItems">
                    <b-dropdown-item>
                      <feather-icon icon="DownloadIcon" /> Download
                      Excel</b-dropdown-item
                    >
                  </download-excel>
                  <b-dropdown-divider />
                </b-dropdown>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block"
                      @keyup="searchFetchData()"
                    />
                  </div>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- Table component -->
          <vue-good-table
            ref="my-table"
            :columns="columns"
            :rows="items"
            max-height="90vh"
            styleClass="vgt-table striped"
            theme="polar-bear"
            
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :sort-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: perPage,
            }"
          >
            <!-- Slot: Table Row -->
            <template slot="table-row" slot-scope="props">
              <!-- Column: is_new -->
              <span v-if="props.column.field === 'item_cover'">
                <b-img width="auto" height="50" :src="props.row.item_cover" />
              </span>
              <!-- Column: is_new -->
              <span v-else-if="props.column.field === 'is_new'">
                <b-badge :variant="props.row.is_new ? 'success' : 'primary'">
                  {{ props.row.is_new ? 'New' : 'Used' }}
                </b-badge>
              </span>
              <!-- Column: status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :class="[
                    props.row.status === 'enabled' ? 'bg-primary' : '',
                    props.row.status === 'review' ? 'bg-success' : '',
                    props.row.status === 'disabled' ? 'bg-dark' : '',
                    props.row.status === 'rejected' ? 'bg-danger' : '',
                  ]"
                >
                  {{ props.row.status }}
                </b-badge>
              </span>
              <!-- Column: original price -->
              <span v-else-if="props.column.field === 'original_price'">
                {{ formatPrice(props.row.original_price) }}
              </span>
              <!-- Column: sale price -->
              <span v-else-if="props.column.field === 'sale_price'">
                {{ formatPrice(props.row.sale_price) }}
              </span>
              <!-- Column: original Discount -->
              <span v-else-if="props.column.field === 'Discount'">
                {{ formatPrice(props.row.Discount) }}
              </span>
              <!-- Column: sale profit -->
              <span v-else-if="props.column.field === 'profit'">
                {{ formatPrice(props.row.profit) }}
              </span>
              <!-- Column: is portal  -->
              <div
                
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'is_portal'"
              >
              <b-badge v-if="props.row.is_portal" variant="primary">Portal</b-badge>
              <b-badge v-else variant="secondary">In Store</b-badge>
                <!-- {{ formatPrice(props.row.profit) }} -->
              </div>
              <!-- Column: category -->
              <span v-else-if="props.column.field === 'category_name'">
                <b-badge variant="info">
                  {{ props.row.category_name }}
                </b-badge>
              </span>
              <!-- Column: brand_logo -->
              <span v-else-if="props.column.field === 'brand_logo'">
                <b-img width="auto" height="20" :src="props.row.brand_logo" />
              </span>
              <!-- Column: Updated At -->
              <div
                @click="onRowClick(props)"
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'created_at'"
              >
                {{ props.row.created_date }}
              </div>
              <!-- Column: Updated At -->
              <div
                @click="onRowClick(props)"
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'deleted_at'"
              >
                {{ props.row.deleted_date }}
              </div>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{
                        path:'/'+
                          (props.row.is_new ? 'New' : 'Used') +
                          '/ShowProduct/variant/show/' +
                          items[props.index].id,
                      }"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Show Product</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="{
                        path:'/'+
                          (props.row.is_new ? 'New' : 'Used') +
                          '/ShowProduct/update/variant/' +
                          items[props.index].id,
                      }"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="perPage"
                    :options="['50', '100', '500']"
                    class="mx-1"
                    @input="filteredData()"
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <!-- pagination -->
                <div>
                  <b-pagination
                    :field="1"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="filteredData()"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: 
  {
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
  },
  data() {
    return {
      authPermissions: null,
      actionForm: {
        originalPrice: '',
        offerAmount: '',
        rate: '',
        rateType: '',
        offerType: '',
        userNumber: '',
        startDate: '',
        endDate: '',
        bulkOptions: ['disabled',  'rejected'],
      },
      filterForm: {
        brands: [],
        vendors: [],
        categories: [],
        status: [],
        items: [],
        minQuantity: '',
        maxQuantity: '',
        minPrice: '',
        maxPrice: '',
        selected: [],
      },
      selectedRowsId: [],
      variantsBucket: [],

      offerTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      rateTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      brandsData: '',
      brands: [],
      brandModel: null,
      vendorData: '',
      vendors: [],
      vendorModel: null,
      categoriesData: '',
      categories: [],
      categoriesModel: null,
      statusData: [],
      statusModel: '',
      itemsData: '',
      itemsBucket: [],
      itemsModel: null,
      minQuan: 0,
      maxQuan: 10,
      minPrice: '',
      maxPrice: '',
      type: '',
      item_cover: '',
      offer_amount: '',
      selected: 'all',
      selectedClassification: 'all',
      data: '',
      dateDefault: null,
      collapseType: 'default',
      totalRows: 1,
      pageLength: 5,
      dir: false,
      // min_quantity: 0,
      // max_quantity: 10,
      sortBy: 'asc',
      orderBy: 'created_at',
      orderOptions: [
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Created at',
          value: 'created_at',
        },
        {
          text: 'deleted at',
          value: 'deleted_at',
        },
      ],
      sortOptionss: [
        {
          text: 'Ascending',
          value: 'asc',
        },
        {
          text: 'Descending',
          value: 'desc',
        },
      ],
      currentPage: 1,
      perPage: 100,
      dir: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: 'Image',
          field: 'item_cover',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'SkU',
          field: 'sku',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Category',
          field: 'category_name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Brand',
          field: 'brand_logo',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Item',
          field: 'item_name',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Vendor Name',
          field: 'vendor_name',
          tdClass: 'text-center',
          thClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'New/Used',
          field: 'is_new',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Name',
          field: 'product_name',
          tdClass: 'text-center',
          thClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Sim Card',
          field: 'sim_cart_value',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Warranty',
          field: 'warranty_type',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'battery health',
          field: 'battery_health',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Imei',
          field: 'imei',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Classification',
          field: 'is_portal',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Quantity',
          field: 'quantity',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Vendor price',
          field: 'original_price',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'sale price',
          field: 'sale_price',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Discount',
          field: 'offer_amount',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'profit',
          field: 'profit',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Created at',
          field: 'created_at',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: 'Deleted at',
          field: 'deleted_at',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: 'Action',
          field: 'actions',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      items: [],
      errors_back: [],
      showDismissibleAlert: false,
      variantsCounter: false,
      searchTerm: '',
      exportItems: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our columns
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  mounted() {},
  created() {
    this.filteredData()
    this.fetchFilterData()
    this.authPermissions = this.$store.state.authPermissions

    if (this.authPermissions.includes('enabled-product')) {
      this.actionForm.bulkOptions.push('enabled')
    }
    if (this.authPermissions.includes('confirmed-product')) {
      this.actionForm.bulkOptions.push('confirmed')
    }
  },
  methods: {
     searchFetchData() {
      // this.fetchData()
      if(this.searchTerm.length >= 3 ||this.searchTerm.length == 0){

        this.fetchData()
      }
    //   if (this.searchTimer) {
    //     clearTimeout(this.searchTimer);
    //     this.searchTimer = null;
    // }
    // this.searchTimer = setTimeout(() => {
    //   console.log("hi")
    //   this.fetchData()
    // }, 3000);
    },
    fetchData() {
      this.currentPage = 1
      this.items.length = 0
      var url = 'variants/data-table/archived'
      var data = {
        min_quantity: this.minQuan,
        max_quantity: this.maxQuan,
        pagi: this.perPage,
        // sort: this.sortBy,
        // order_by: this.orderBy,
      }
      if (this.orderBy != '') {
        data['order_by'] = this.orderBy
      }
      if (this.sortBy != '') {
        data['sort'] = this.sortBy
      }
      if (this.searchTerm != '') {
        data['search'] = this.searchTerm
      }
      if (this.brandModel != null && this.brandModel != '') {
        data['brand_id'] = this.brandModel.value
      }
      if (this.categoriesModel != null && this.categoriesModel != '') {
        data['category_id'] = this.categoriesModel.value
      }
      if (this.itemsModel != null && this.itemsModel != '') {
        data['item_id'] = this.itemsModel.value
      }
      if (this.vendorModel != null && this.vendorModel != '') {
        data['vendor_id'] = this.vendorModel.value
      }
      if (this.selected == 'new' || this.selected == 'used') {
        data['variant_type'] = this.selected
      }
       if (this.selectedClassification == 1 || this.selectedClassification == 0) {
        data['is_portal'] = this.selectedClassification
      }
      if (this.statusModel != '' && this.statusModel != null) {
        data['status'] = this.statusModel
      }
      if (this.minPrice != null) {
        data['min_price'] = this.minPrice
      }
      if (this.maxPrice != null) {
        data['max_price'] = this.maxPrice
      }
      if (this.maxPrice <= this.minPrice) {
        this.errors_back = []
        this.showDismissibleAlert = true
        this.errors_back.push([['Max price must be greater than min price']])
        setTimeout(() => {
          this.showDismissibleAlert = false
        }, 3000)
      }
      if (this.maxQuan <= this.minQuan) {
        this.errors_back = []
        this.showDismissibleAlert = true
        this.errors_back.push([
          ['Max quantity must be greater than min quantity'],
        ])
        setTimeout(() => {
          this.showDismissibleAlert = false
        }, 3000)
      }
      axios
        .post(url, data)
        .then((response) => {
          const data = response.data.data.data
          this.totalRows = response.data.data.total
          this.items.length = 0
          this.items = response.data.data.data
          // for (let index in data) {
          //   this.items.push({
          //     // brand_name: data[index].brand_name,
          //     category_name: data[index].category_name,
          //     created_at: data[index].created_date,
          //     id: data[index].id,
          //     sku: data[index].sku,
          //     is_new: data[index].is_new,
          //     item_name: data[index].item_name,
          //     original_price: data[index].original_price,
          //     price: data[index].price,
          //     product_name: data[index].product_name,
          //     profit: data[index].profit,
          //     quantity: data[index].quantity,
          //     sale_price: data[index].sale_price,
          //     status: data[index].status,
          //     deleted_at: data[index].deleted_date,
          //     vendor_name: data[index].vendor_name,
          //     search: data[index].searchTerm,
          //     brand_logo: data[index].brand_logo,
          //     item_cover: data[index].item_cover,
          //     offer_amount: data[index].offer_amount,
          //     battery_health: data[index].battery_health,
          //   })
          // }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
      if (this.items.length == 0) {
        this.setExportItems(this.items)

        this.items = []
      } else {
        this.setExportItems(this.items)
        return this.items
      }
    },
    fetchFilterData() {
      axios
        .get('variants/data-table/filter/archived')
        .then((response) => {
          this.brandsData = response.data.data.brands
          this.brandsData.forEach((el) => {
            return this.brands.push({
              text: el.name,
              value: el.id.toString(),
            })
          })
          this.vendorData = response.data.data.vendors
          this.vendorData.forEach((el) => {
            return this.vendors.push({
              text: el.storename,
              value: el.id.toString(),
            })
          })
          this.categoriesData = response.data.data.categories
          this.categoriesData.forEach((el) => {
            return this.categories.push({
              text: el.name,
              value: el.id.toString(),
            })
          })
          this.itemsData = response.data.data.items
          this.itemsData.forEach((el) => {
            return this.itemsBucket.push({
              text: el.name,
              value: el.id.toString(),
            })
          })
          this.statusData = response.data.data.status
          this.minQuan = response.data.data.min_quantity
          this.maxQuan = response.data.data.max_quantity
          this.minPrice = response.data.data.min_price
          this.maxPrice = response.data.data.max_price
          this.type = response.data.data.type
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    filteredData() {
      this.items.length = 0
      var url = 'variants/data-table/archived' + '?page=' + this.currentPage
      var data = {
        min_quantity: this.minQuan,
        max_quantity: this.maxQuan,
        pagi: this.perPage,
      }
      if (this.brandModel != null && this.brandModel != '') {
        data['brand_id'] = this.brandModel.value
      }
      if (this.categoriesModel != null && this.categoriesModel != '') {
        data['category_id'] = this.categoriesModel.value
      }
      if (this.itemsModel != null && this.itemsModel != '') {
        data['item_id'] = this.itemsModel.value
      }
      if (this.vendorModel != null && this.vendorModel != '') {
        data['vendor_id'] = this.vendorModel.value
      }
      if (this.selected == 'new' || this.selected == 'used') {
        data['variant_type'] = this.selected
      }
       if (this.selectedClassification == 1 || this.selectedClassification == 0) {
        data['is_portal'] = this.selectedClassification
      }
      if (this.statusModel != '' && this.statusModel != null) {
        data['status'] = this.statusModel
      }
      if (this.minPrice != null) {
        data['min_price'] = this.minPrice
      }
      if (this.maxPrice != null) {
        data['max_price'] = this.maxPrice
      }
      if (this.searchTerm != '') {
        data['search'] = this.searchTerm
      }
      axios
        .post(url, data)
        .then((response) => {
          // const data = response.data.data.data
          this.totalRows = response.data.data.total
          this.items.length = 0
          this.items = response.data.data.data
          // for (let index in data) {
          //   this.items.push({
          //     // brand_name: data[index].brand_name,
          //     category_name: data[index].category_name,
          //     created_at: data[index].created_date,
          //     id: data[index].id,
          //     sku: data[index].sku,
          //     is_new: data[index].is_new,
          //     item_name: data[index].item_name,
          //     original_price: data[index].original_price,
          //     price: data[index].price,
          //     product_name: data[index].product_name,
          //     profit: data[index].profit,
          //     quantity: data[index].quantity,
          //     sale_price: data[index].sale_price,
          //     status: data[index].status,
          //     deleted_at: data[index].deleted_date,
          //     vendor_name: data[index].vendor_name,
          //     search: data[index].searchTerm,
          //     brand_logo: data[index].brand_logo,
          //     item_cover: data[index].item_cover,
          //     offer_amount: data[index].offer_amount,
          //     battery_health: data[index].battery_health,
          //   })
          // }
          if (this.items.length == 0) {
            this.setExportItems(this.items)

            this.items = []
          } else {
            this.setExportItems(this.items)
            return this.items
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    RemoveExitem() {
      //console.log('test')
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].brand_logo
        delete this.exportItems[ex].item_cover
        delete this.exportItems[ex].is_new
      }
    },
    setExportItems(data) {
      for (let index in data) {
        this.exportItems.push({
          sku: data[index].sku,
          vendor_name: data[index].vendor_name,
          category_name: data[index].category_name,

          type: data[index].is_new == false ? 'used' : 'true',
          item_name: data[index].item_name,
          product_name: data[index].product_name,
          original_price: data[index].original_price,
          price: data[index].price,
          profit: data[index].profit,
          sale_price: data[index].sale_price,
          offer_amount: data[index].offer_amount,
          quantity: data[index].quantity,
          status: data[index].status,

          battery_health: data[index].battery_health,
          Create_date: data[index].created_at,
          modified_at: data[index].deleted_at,
        })
      }
    },
    resetFilter() {
      this.minQuan = 0
      this.maxQuan = 20
      this.minPrice = 100
      this.maxPrice = 100000

      this.brandModel = null
      this.categoriesModel = null
      this.vendorModel = null
      this.itemsModel = null
      this.statusModel = ''
      this.selected = 'all'
      this.selectedClassification = 'all'
      this.filteredData()
    },
    deDuctCountOfVariant() {
      this.selectedRowsId = []
      this.x = this.$refs['my-table'].selectedRows
      this.x.forEach((element) => {
        this.selectedRowsId.push(element.id)
      })
      if (this.selectedRowsId == 0 || this.selectedRowsId == null) {
        this.errors_back = []
        this.showDismissibleAlert = true
        this.errors_back.push([['Please Add at least one variant']])
        setTimeout(() => {
          this.showDismissibleAlert = false
        }, 6000)
      } else {
      }
    },
    applyBulkStatusUpdate() {
      this.selectedRowsId = []
      this.x = this.$refs['my-table'].selectedRows
      this.x.forEach((element) => {
        this.selectedRowsId.push(element.id)
      })
      axios
        .post('variants/pulk/restore', {
          variants: this.selectedRowsId,
        })
        .then((response) => {
          if (response.data.success) {
            this.fetchData()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
<style lang="scss">

.vgt-table thead th,
.vgt-table td,
.vgt-table td span {
  white-space: nowrap !important;
  text-transform: capitalize !important;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #08308B !important;
  background-color: #08308B !important;
}

.btn-color {
  background-color: #08308B !important;
  border-color: #08308B !important;
}
</style>
